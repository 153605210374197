import React, { useEffect, useState } from "react";
import Select from "react-select";
import { registerLocale } from "react-datepicker";
import tr from "date-fns/locale/tr";
import "react-datepicker/dist/react-datepicker.css";

import {
  addNewTicket,
  changePassword,
  getNotes,
  getUser,
} from "../../ourapis/api";
import { useStateContext } from "../../contexts/ContextProvider";
import { useLocation, useNavigate } from "react-router-dom";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";

registerLocale("tr", tr);

const ChangePassPage = () => {
  const { jwtToken, myEmail } = useStateContext();
  const location = useLocation();
  const navigate = useNavigate();

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordAgain, setNewPasswordAgain] = useState("");

  return (
    <div>
      <div className="m-2 mt-20 md:mt-0 pb-20 md:pb-0 md:m-4 p-2 md:p-6 flex justify-between flex-wrap relative ">
        {/* SOL TARAF - YENI KAYIT EKLE BOLUMU */}
        <div className="w-full">
          {/* Sayfa Başlığı */}
          <h3
            style={{
              fontFamily: "Poppins Regular",
              fontSize: 24,
              color: "#333333",
              marginBottom: 60,
            }}
          >
            Şifre Değiştir
          </h3>

          <InputCard
            title={"Eski Şifre"}
            setText={setOldPassword}
            placeholder={"Eski şifreniz"}
          />

          <InputCard
            title={"Yeni Şifre"}
            setText={setNewPassword}
            placeholder={"Yeni şifreniz"}
          />

          <InputCard
            title={"Yeni Şifre Tekrar"}
            setText={setNewPasswordAgain}
            placeholder={"Yeni şifreniz tekrar"}
          />

          {/* Kaydet Butonu */}
          <button
            className="w-[100px] h-[50px] bg-[#5356FF] rounded-sm flex items-center justify-center mt-8 md:ml-8"
            onClick={() => {
              if (
                oldPassword.length < 3 ||
                newPassword.length < 3 ||
                newPasswordAgain.length < 3
              ) {
                Swal.fire({
                  title: "Uyarı",
                  text: "Lütfen tüm boşlukları en az 3 karakter ile doldurun",
                  icon: "warning",
                  confirmButtonText: "Tamam",
                });
              } else {
                changePassword(myEmail, jwtToken, oldPassword, newPassword);
              }
            }}
          >
            <p
              style={{
                fontFamily: "Poppins Medium",
                fontSize: 20,
                color: "white",
              }}
            >
              Kaydet
            </p>
          </button>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

const InputCard = (props) => {
  return (
    <>
      <p
        className="md:ml-8"
        style={{
          fontFamily: "Poppins Regular",
          color: "gray",
          fontSize: 18,
          marginTop: 20,
        }}
      >
        {props.title}:
      </p>
      <input
        className=" border-1 mt-2  md:ml-8 border-[#707070] h-12 w-full md:w-400 shadow-sm  rounded-sm px-3  focus:outline-none focus:border-sky-500 focus:ring-sky-500 placeholder:text-slate-400 block bg-white sm:text-sm"
        placeholder={props.placeholder}
        onChange={(e) => {
          props.setText(e.target.value);
        }}
        maxLength={50}
      />
    </>
  );
};

export default ChangePassPage;
