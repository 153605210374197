import axios from "axios";
import axiosInstance, { baseURL } from "../common/baseURL";
import Swal from "sweetalert2";

// Email ile bir müşteriyi getir
export const getUser = async (email, jwt, setName, setEmail, setBalance) => {
  await axiosInstance
    .get(`/users/${email}`, {
      headers: { Authorization: `Bearer ${jwt}` },
    })
    .then((res) => {
      setName(res.data.name);
      setEmail(res.data.email);
      setBalance(res.data.balance);
    })
    .catch((err) => {
      console.log(err);
    });
};

// Durum bilgisine göre tüm müşterileri getir
export const getAllUsers = async (email, jwt, setUsers) => {
  await axios
    .get(`${baseURL}/users/get/allUsers/${email}`, {
      headers: { Authorization: `Bearer ${jwt}` },
    })
    .then((res) => {
      setUsers(res.data);
    })
    .catch((err) => {
      console.log(err);
      console.log(err.response.msg);
    });
};

export const deleteUser = async (email, jwt, userEmail) => {
  await axios
    .post(
      `${baseURL}/users/deleteUser/${email}`,
      {
        email: userEmail,
      },
      {
        headers: { Authorization: `Bearer ${jwt}` },
      }
    )
    .then((res) => {
      Swal.fire({
        title: "Kullanıcı silindi.",
        icon: "success",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Tamam",
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload(false);
        }
      });
    })
    .catch((err) => {
      Swal.fire({
        title: "İşlem sırasında hata oluştu.",
        icon: "error",
        confirmButtonColor: "red",
        confirmButtonText: "Tamam",
      });
      console.log(err);
      console.log(err.response.msg);
    });
};

export const getAllWords = async (email, jwt, setWords) => {
  await axios
    .get(`${baseURL}/words/getAllWords/${email}`, {
      headers: { Authorization: `Bearer ${jwt}` },
    })
    .then((res) => {
      setWords(res.data.words);
    })
    .catch((err) => {
      console.log(err);
      console.log(err.response.msg);
    });
};

// Tüm müşterileri getir
export const getNotes = async (owner, jwt, setNotes) => {
  await axiosInstance
    .get(`/notes/get/allNotes/${owner}`, {
      headers: { Authorization: `Bearer ${jwt}` },
    })
    .then((res) => {
      setNotes(res.data);
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getBalanceLogs = async (email, owner, jwt, setBalanceLogs) => {
  await axiosInstance
    .get(`/users/allBalanceLogs/${email}/${owner}`, {
      headers: { Authorization: `Bearer ${jwt}` },
    })
    .then((res) => {
      setBalanceLogs(res.data.balanceLogs);
    })
    .catch((err) => {
      console.log(err);
    });
};

// Ticket'ı yanıtlama API
export const addBalance = async (
  owner,
  walledID,
  myEmail,
  addedBalance,
  jwt
) => {
  await axios
    .post(
      `${baseURL}/users/addBalance/${myEmail}`,
      {
        owner,
        walledID,
        addedBalance,
      },
      {
        headers: { Authorization: `Bearer ${jwt}` },
      }
    )
    .then((res) => {
      console.log("Başarılı");
    })
    .catch((err) => {
      console.log(err.response.data);
    });
};

export const getUserBalance = async (owner, myEmail, setBalance, jwt) => {
  await axios
    .get(
      `${baseURL}/users/userBalance/${myEmail}/${owner}`,

      {
        headers: { Authorization: `Bearer ${jwt}` },
      }
    )
    .then((res) => {
      setBalance(res.data.balance);
    })
    .catch((err) => {
      console.log(err.response.data);
    });
};

export const login = async (email, password, setJwtToken, navigate) => {
  await axios
    .post(`${baseURL}/users/login`, {
      email,
      password,
    })
    .then((res) => {
      localStorage.setItem("jwtToken", res.data.token);
      localStorage.setItem("myEmail", email);
      localStorage.setItem("name", res.data.user.name);
      localStorage.setItem("userInfos", JSON.stringify(res.data.user));
      setJwtToken(res.data.token);
      navigate("/");
      window.location.reload(false);
    })
    .catch((err) => {
      console.log(err.response.data);
      Swal.fire({
        title: "Email veya şifre hatalı",
        icon: "warning",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Tamam",
      });
    });
};

export const setTicketClosed = async (id, email, jwt) => {
  await axios
    .put(
      `${baseURL}/tickets/closeTicket/${id}/${email}`,
      {
        isOpen: false,
      },
      {
        headers: { Authorization: `Bearer ${jwt}` },
      }
    )
    .then((res) => {
      console.log("Başarılı");
      Swal.fire({
        title: "Ticket Kapatıldı.",
        icon: "success",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Tamam",
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload(false);
        }
      });
    })
    .catch((err) => {
      console.log(err);
      console.log("Başarısız");
    });
};

export const updateWord = async (email, jwt, word) => {
  await axios
    .put(
      `${baseURL}/words/updateWord/${email}`,
      {
        word: word,
      },
      {
        headers: { Authorization: `Bearer ${jwt}` },
      }
    )
    .then((res) => {
      console.log("Başarılı");
      Swal.fire({
        title: "Kelime Güncellendi.",
        icon: "success",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Tamam",
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload(false);
        }
      });
    })
    .catch((err) => {
      console.log(err);
      console.log("Başarısız");
    });
};

export const addNewWord = async (email, jwt, word) => {
  await axios
    .post(
      `${baseURL}/words/addWord/${email}`,
      {
        wordTurkce: word.wordTurkce,
        wordKazakca: word.wordKazakca,
        wordRusca: word.wordRusca,
        imageURL: word.imageURL,
        description: word.description,
        category: word.category,
      },
      {
        headers: { Authorization: `Bearer ${jwt}` },
      }
    )
    .then((res) => {
      console.log("Başarılı");
      Swal.fire({
        title: "Yeni kelime kaydedildi.",
        icon: "success",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Tamam",
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload(false);
        }
      });
    })
    .catch((err) => {
      console.log(err);
      console.log("Başarısız");
    });
};

// Yeni Destek Talebi Oluşturan API
export const addNewTicket = async (
  email,
  jwt,
  service,
  ticketTitle,
  priority,
  msg,
  ticketImgUri
) => {
  await axios
    .post(
      `${baseURL}/tickets/addTicket/${email}`,
      {
        service,
        ticketTitle,
        priority,
        msg,
        ticketImgUri,
      },
      {
        headers: { Authorization: `Bearer ${jwt}` },
      }
    )
    .then((res) => {
      console.log("Başarılı");
      Swal.fire({
        title: "Talep Oluşturuldu.",
        icon: "success",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Tamam",
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload(false);
        }
      });
    })
    .catch((err) => {
      console.log(err);
      console.log("Başarısız");
    });
};

export const getUserCount = async (email, jwt, setUserCount) => {
  await axios
    .get(`${baseURL}/users/get/totalUserCount/${email}`, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    })
    .then((res) => {
      setUserCount(res.data.totalUserCount);
    })
    .catch((err) => {
      console.log(err);
    });
};

export const changePassword = async (email, jwt, oldPassword, newPassword) => {
  await axios
    .put(
      `${baseURL}/users/changePassword/${email}`,
      {
        oldPassword,
        newPassword,
      },
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      }
    )
    .then((res) => {
      Swal.fire({
        title: "Başarılı",
        text: "Yeni şifreniz kaydedildi.",
        icon: "success",
        confirmButtonText: "Tamam",
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload(false);
        }
      });
    });
};
