import React, { useEffect, useState } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { SiShopware } from "react-icons/si";
import { MdOutlineCancel } from "react-icons/md";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";

import { links } from "../data/dummy";
import { useStateContext } from "../contexts/ContextProvider";
import Swal from "sweetalert2";

const Sidebar = () => {
  const {
    activeMenu,
    setActiveMenu,
    screenSize,
    currentColor,
    setScreenSize,
    setJwtToken,
    name,
  } = useStateContext();

  const navigate = useNavigate();
  const location = useLocation();

  // Ekran boyutu ile mobil olup olmadığını anladığımız yer
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);
    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (screenSize <= 900) {
      setActiveMenu(false);
      setIsMobile(true);
    } else {
      setActiveMenu(true);
      setIsMobile(false);
    }
  }, [screenSize]);

  const activeLink =
    "flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg text-white text-md m-2";

  const normalLink =
    "flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg text-md text-gray-500 dark:text-gray-200  m-2";

  const handleCloseSideBar = () => {
    if (activeMenu && screenSize <= 900) {
      setActiveMenu(false);
    }
  };
  return (
    <div className="ml-3 h-screen md:overflow-hidden overflow-auto md:hover:overflow-auto pb-10 bg-white">
      {activeMenu && (
        <>
          <div className="flex justify-between items-center">
            <Link
              to={"/"}
              onClick={handleCloseSideBar}
              className={
                "items-center gap-3 mx-3 mr-6 mt-4 flex text-xl font-extrabold tracking-tight text-black w-full justify-center"
              }
            >
              <span
                style={{
                  fontFamily: "Poppins Regular",
                  fontSize: 24,
                  marginTop: 10,
                  textAlign: "center",
                }}
              >
                <span style={{ fontSize: 20 }}>Leksilink</span> <br /> Admin
                Paneli <br />
                <span style={{ fontSize: 16 }}>Hoş geldin, {name}</span>
              </span>
            </Link>
            {isMobile && (
              <TooltipComponent content={"Menu"} position={"BottomCenter"}>
                <button
                  type="button"
                  onClick={() =>
                    setActiveMenu((prevActiveMenu) => !prevActiveMenu)
                  }
                  className={
                    "text-xl rounded-full p-3 hover:bg-l[#000] mt-4 block"
                  }
                  style={{ color: "black" }}
                >
                  <MdOutlineCancel />
                </button>
              </TooltipComponent>
            )}
          </div>
          <div className="mt-10">
            {links.map((item) => (
              <div key={item.title}>
                <p
                  className="text-black m-3 mt-4 opacity-60"
                  style={{ fontFamily: "Montserrat Regular" }}
                >
                  {item.title}
                </p>
                {item.links.map((Link) => (
                  <NavLink
                    key={Link.linkName}
                    to={Link.linkName === "cikis" ? "/" : `/${Link.linkName}`}
                    onClick={() => {
                      if (Link.linkName === "cikis") {
                        // SWEET ALERT İLE ÇIKIŞ YAPMAK İSTİYOR MUSUN ALERT GÖSTERİLDİ
                        Swal.fire({
                          title: "Çıkış yapmak istiyor musun?",
                          text: "Çıkış yaptığınızda oturumunuz sonlandırılacaktır",
                          icon: "warning",
                          showCancelButton: true,
                          confirmButtonColor: "#3085d6",
                          cancelButtonColor: "#d33",
                          confirmButtonText: "Çıkış Yap",
                          cancelButtonText: "İptal Et",
                        }).then((result) => {
                          if (result.isConfirmed) {
                            localStorage.removeItem("jwtToken");

                            setJwtToken("");
                            navigate("/login");
                            window.location.reload(false);
                          }
                        });
                      } else {
                        handleCloseSideBar();
                      }
                    }}
                    style={({ isActive }) => ({
                      backgroundColor:
                        isActive && Link.linkName !== "cikis"
                          ? "#5356FF"
                          : location.pathname === "/" &&
                            Link.linkName === "kelimeler"
                          ? "#5356FF"
                          : "",
                      color:
                        location.pathname === "/" &&
                        Link.linkName === "kelimeler"
                          ? "white"
                          : Link.linkName === "cikis"
                          ? "gray"
                          : "",
                    })}
                    className={({ isActive }) =>
                      isActive ? activeLink : normalLink
                    }
                  >
                    {Link.icon}
                    <span
                      className=" capitalize"
                      style={{
                        fontFamily: "Poppins Regular",
                      }}
                    >
                      {Link.name}
                    </span>
                  </NavLink>
                ))}
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default Sidebar;
