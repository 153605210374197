import React, { useEffect, useState } from "react";
import { AiOutlineMenu } from "react-icons/ai";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import { useStateContext } from "../contexts/ContextProvider";
import { CgProfile } from "react-icons/cg";
import { FaUserCircle } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import logo from "../data/logo.png";
import { MdLogout } from "react-icons/md";
import Swal from "sweetalert2";

const NavButton = ({ title, customFunc, icon, color, dotColor }) => (
  <TooltipComponent content={title} position={"BottomCenter"}>
    <button
      type="button"
      onClick={customFunc}
      style={{ color }}
      className={"relative text-xl rounded-full p-3 hover:bg-light-gray"}
    >
      <span
        style={{ background: dotColor }}
        className={"absolute inline-flex rounded-full h-2 w-2 right-2 top-2"}
      />
      {icon}
    </button>
  </TooltipComponent>
);

const Navbar = () => {
  const { setActiveMenu, screenSize, setScreenSize, setJwtToken } =
    useStateContext();

  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);
    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (screenSize <= 900) {
      setActiveMenu(false);
    } else {
      setActiveMenu(true);
    }
  }, [screenSize]);

  const navigate = useNavigate();

  const handleButtonClick = () => {
    Swal.fire({
      title: "Çıkış yapmak üzeresiniz.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Çıkış Yap",
      cancelButtonText: "İptal Et",
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.removeItem("jwtToken");

        setJwtToken("");
        navigate("/login");
        window.location.reload(false);
      }
    });
  };

  // Ekran boyutu ile mobil olup olmadığını anladığımız yer
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);
    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (screenSize <= 900) {
      setActiveMenu(false);
      setIsMobile(true);
    } else {
      setActiveMenu(true);
      setIsMobile(false);
    }
  }, [screenSize]);

  return (
    <div className="flex justify-between items-center p-2 md:mx-6 relative">
      <NavButton
        title={"Menu"}
        customFunc={() => setActiveMenu((prevActiveMenu) => !prevActiveMenu)}
        color={"gray"}
        icon={<AiOutlineMenu />}
      />

      {isMobile && (
        <>
          <img src={logo} style={{ width: 180 }} alt="Logo" />
          <NavButton
            title={"Çıkış"}
            customFunc={handleButtonClick}
            color={"gray"}
            icon={<MdLogout size={24} />}
          />
        </>
      )}
    </div>
  );
};

export default Navbar;
