import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { FiSettings } from "react-icons/fi";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";

import LoginPage from "./pages/auth/Login";

import { Navbar, Sidebar } from "./components";
import { useStateContext } from "./contexts/ContextProvider";
import "./App.css";
import CustomerTicketsPage from "./pages/users/UsersPage";

import HomePage from "./pages/home/HomePage";
import ChangePassPage from "./pages/settings/ChangePassPage";

const App = () => {
  const {
    activeMenu,
    setThemeSettings,
    currentColor,
    currentMode,
    setJwtToken,
    setMyEmail,
    setName,
    setUserInfos,
  } = useStateContext();

  const [userValue4Route, setUserValue4Route] = useState(true);
  const [showAllScreen, setShowAllScreen] = useState(false);

  useEffect(() => {
    //localStorage.removeItem("jwtToken")
    const userToken = localStorage.getItem("jwtToken");
    const myEmail = localStorage.getItem("myEmail");
    const name = localStorage.getItem("name");
    const userInfos = localStorage.getItem("userInfos");

    setShowAllScreen(true);
    if (userToken && myEmail) {
      setJwtToken(userToken);
      setMyEmail(myEmail);
      setName(name);
      setUserInfos(JSON.parse(userInfos));
      setUserValue4Route(false);
    }

    //localStorage.setItem("jwtToken", "");
  }, []);

  if (userValue4Route && showAllScreen) {
    return (
      <BrowserRouter>
        <Routes>
          {/* Auth */}
          <Route path="/" element={<LoginPage />} />
          <Route path="/:someword" element={<LoginPage />} />
        </Routes>
      </BrowserRouter>
    );
  }

  return (
    <div className={currentMode === "Dark" ? "dark" : ""}>
      <BrowserRouter>
        <div className="flex relative dark:bg-main-dark-bg">
          {activeMenu ? (
            <div className="w-72 fixed sidebar dark:bg-secondary-dark-bg bg-[#333333]">
              <Sidebar />
            </div>
          ) : (
            <div className="w-0 dark:bg-secondary-dark-bg bg-[#333333]">
              <Sidebar />
            </div>
          )}
          <div
            className={`dark:bg-main-dark-bg bg-[#E9EAEC] min-h-screen w-full ${
              activeMenu ? "md:ml-72 " : "flex-2"
            }`}
          >
            <div
              className="fixed md:static bg-main-bg dark:bg-main-dark-bg shadow-sm navbar w-full"
              style={{
                borderBottomLeftRadius: 7,
                borderBottomRightRadius: 7,
              }}
            >
              <Navbar />
            </div>

            <div>
              <Routes>
                {/* Dashboard */}
                <Route path="/" element={<HomePage />} />
                <Route path="/kelimeler" element={<HomePage />} />
                <Route path="/users" element={<CustomerTicketsPage />} />
                <Route path="/sifre-degistir" element={<ChangePassPage />} />
              </Routes>
            </div>
          </div>
        </div>
      </BrowserRouter>
    </div>
  );
};

export default App;
