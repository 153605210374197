import React, { useState } from "react";
import { format } from "date-fns";

export default function UserCard(props) {
  return (
    <div className="flex items-center">
      <button
        className="bg-white shadow-md hover:shadow-lg w-[96%] h-[50px] items-center flex justify-between"
        style={{ borderRadius: 4, marginTop: 20 }}
        onClick={props.onClick}
      >
        {/* ID */}
        <div
          className=""
          style={{
            fontFamily: "Poppins Regular",
            color: "#333333",
            fontSize: 18,
            marginLeft: 10,
            width: 50,
          }}
        >
          {props.id + 1}
        </div>

        {/* Türkçe */}
        <div
          className=""
          style={{
            fontFamily: "Poppins Regular",
            color: "#333333",
            fontSize: 18,
            marginLeft: 10,
            width: 200,
          }}
        >
          {props.item.wordTurkce}
        </div>

        {/* Kazakça */}
        <div
          className=""
          style={{
            fontFamily: "Poppins Regular",
            color: "#333333",
            fontSize: 18,
            marginLeft: 10,
            width: 200,
          }}
        >
          {props.item.wordKazakca}
        </div>

        {/* Rusça */}
        <div
          className=""
          style={{
            fontFamily: "Poppins Regular",
            color: "#333333",
            fontSize: 18,
            marginLeft: 10,
            width: 200,
          }}
        >
          {props.item.wordRusca}
        </div>

        {/* Tarih */}
        <div
          className=""
          style={{
            fontFamily: "Poppins Regular",
            color: "#333333",
            fontSize: 18,
            marginLeft: 10,
            width: 200,
          }}
        >
          {format(new Date(props.item?.createdAt), "dd.MM.yyyy HH:mm")}
        </div>
      </button>
    </div>
  );
}
