import React from "react";
import { MdLogout, MdSpaceDashboard } from "react-icons/md";
import { FaCode, FaLock, FaQuoteLeft, FaUsers } from "react-icons/fa";
import { IoTicket } from "react-icons/io5";
import { IoIosCreate } from "react-icons/io";

export const links = [
  {
    title: "Panel",
    links: [
      {
        name: "Kelimeler",
        linkName: "kelimeler",
        icon: <MdSpaceDashboard />,
      },
      {
        name: "Kullanıcılar",
        linkName: "users",
        icon: <FaUsers />,
      },
    ],
  },

  {
    title: "Ayarlar",
    links: [
      {
        name: "Şifre Değiştir",
        linkName: "sifre-degistir",
        icon: <FaLock />,
      },
      {
        name: "Çıkış",
        linkName: "cikis",
        icon: <MdLogout />,
      },
    ],
  },
];
