import React, { useState } from "react";
import { AiFillEye } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { useStateContext } from "../../contexts/ContextProvider";
import { login } from "../../ourapis/api";
import logo from "../../data/logo.png";

function LoginPage() {
  const { setJwtToken } = useStateContext();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passShown, setPassShown] = useState(false);

  const navigate = useNavigate();

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      if (email !== "" && password !== "") {
        login(email, password, setJwtToken, navigate);
      } else {
        alert("Boş geçilemez!");
      }
    }
  };

  return (
    <div
      className=" flex justify-center items-center bg-[#E9EAEC]  "
      style={{ height: "100vh" }}
    >
      <div className=" bg-white p-10 border-1 shadow-lg rounded-xl">
        <div className="items-center flex justify-center">
          <img src={logo} style={{ width: 240 }} alt="Logo" />
        </div>

        <p className="mt-8  md:ml-8" style={{ fontFamily: "Poppins Medium" }}>
          Email:
        </p>
        <input
          className=" border-1 mt-2  md:ml-8 border-[#707070] h-12 w-full md:w-400 shadow-sm  rounded-lg px-3  focus:outline-none focus:border-sky-500 focus:ring-sky-500 placeholder:text-slate-400 block bg-white sm:text-sm"
          placeholder="Email adresinizi girin"
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          maxLength={50}
        />

        <p className="mt-5  md:ml-8" style={{ fontFamily: "Poppins Medium" }}>
          Şifre:
        </p>

        <div
          className=""
          style={{
            flexDirection: "row",
            display: "flex",
          }}
        >
          <input
            className=" border-1 mt-2  md:ml-8 border-[#707070] h-12 w-full md:w-400 shadow-sm  rounded-lg px-3  focus:outline-none focus:border-sky-500 focus:ring-sky-500 placeholder:text-slate-400 block bg-white sm:text-sm"
            placeholder="Şifrenizi girin"
            type={passShown ? "text" : "password"}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            onKeyPress={handleKeyPress}
            maxLength={20}
          />

          <AiFillEye
            size={24}
            style={{ alignSelf: "center", marginLeft: -40, marginTop: 7 }}
            onClick={() => {
              setPassShown(!passShown);
            }}
          />
        </div>

        {/* Buton */}
        <div className="flex justify-center  mt-8">
          <button
            className={` w-72 h-16 rounded-xl bg-[#5356FF]  shadow-xl  ${
              false ? "opacity-50" : "active:bg-blue-700  "
            } hover:shadow-2xl`}
            onClick={() => {
              if (email !== "" && password !== "") {
                login(email, password, setJwtToken, navigate);
              } else {
                alert("Boş geçilemez!");
              }
            }}
          >
            <div
              className="text-white font-bold text-xl"
              style={{ fontFamily: "Poppins Medium" }}
            >
              Giriş Yap
            </div>
          </button>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
