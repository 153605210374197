import React, { useEffect, useState } from "react";

import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import tr from "date-fns/locale/tr";

import {
  addBalance,
  deleteUser,
  getAllUsers,
  getBalanceLogs,
  setTicketClosed,
} from "../../ourapis/api";
import { useStateContext } from "../../contexts/ContextProvider";
import { useNavigate } from "react-router-dom";

import { AiFillCaretDown } from "react-icons/ai";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NotCard from "../../components/NotCard";
import Swal from "sweetalert2";
import SingleUserCard from "../../components/SingleUserCard";
import { MdOutlineCancel } from "react-icons/md";

registerLocale("tr", tr);

const tableData = [
  {
    title: "ID",
  },

  {
    title: "İsim",
  },
  {
    title: "E-mail",
  },
  {
    title: "Tarih",
  },
];

const CustomerTicketsPage = () => {
  const { jwtToken, myEmail, setScreenSize, screenSize } = useStateContext();

  const [showUserModal, setShowUserModal] = useState(false);
  const [clickedTicketID, setClickedTicketID] = useState();
  const [clickedTicketTitle, setClickedTicketTitle] = useState();

  const [clickedUser, setClickedUser] = useState();

  const [searchText, setSearchText] = useState(""); // Arama metni için bir durum (state)
  const [searchResult, setSearchResult] = useState();
  const [searched, setSearched] = useState(false);
  const [users, setUsers] = useState([]);

  const [sorted, setSorted] = useState(false);
  const [sortedName, setSortedName] = useState("Tarih");
  const [sortedData, setSortedData] = useState([]);

  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    if (jwtToken) {
      getAllUsers(myEmail, jwtToken, setUsers);
    }
  }, [jwtToken]);

  // Arama kutusuna girilen metni güncelleyen işlev
  const handleSearchTextChange = (e) => {
    setSearchText(e.target.value);

    if (e.target.value === "") {
      setSearched(false);
    }
  };

  // Arama işlemini gerçekleştiren işlev
  const performSearch = () => {
    setSearched(true);
    const lowerSearchText = searchText.toLowerCase();

    // Arama metni ile eşleşen müşterileri filtrele
    const filteredUsers = users.filter((item) => {
      return item.ticketTitle.toLowerCase().includes(lowerSearchText);
    });

    // Filtrelenmiş müşterileri göster
    // Daha sonra bu müşterileri map ile döngüye alabilirsiniz.
    setSearchResult(filteredUsers);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      performSearch();
    }
  };

  // DATA - Sıralama işlemleri
  const onTitleClickHandler = (title) => {
    if (title === "Tarih") {
      // Tarihe göre sıralama işlemi
      let sortedDatas = users;
      if (sorted) {
        sortedDatas.sort((a, b) => {
          const dateA = new Date(a.createdAt);
          const dateB = new Date(b.createdAt);
          return dateA - dateB;
        });
      } else {
        sortedDatas.sort((a, b) => {
          const dateA = new Date(a.createdAt);
          const dateB = new Date(b.createdAt);
          return dateB - dateA;
        });
      }

      setSortedData(sortedDatas);
      setSorted(!sorted);
      setSortedName("Tarih");
    }
  };

  // MODAL IÇIN GEREKLI BÖLÜMLER
  // MODAL IÇIN GEREKLI BÖLÜMLER
  // MODAL IÇIN GEREKLI BÖLÜMLER
  // MODAL IÇIN GEREKLI BÖLÜMLER

  // diğer sayfalardan gelen mail bilgisini tutuyoruz

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [walletID, setWalletID] = useState("");
  const [balance, setBalance] = useState("");

  const [startDate, setStartDate] = useState(Date.now());

  const [addedBalance, setAddedBalance] = useState(""); // Arama metni için bir durum (state)
  const [balanceLogs, setBalanceLogs] = useState(""); // Arama metni için bir durum (state)

  // Güncelleme işlemleri sonrası gösterilecek alert
  const notifySuccess = (text) =>
    toast.success(text, {
      position: toast.POSITION.TOP_CENTER,
    });

  const handleNoteTextChange = (e) => {
    setAddedBalance(e.target.value);
  };

  const handleKeyPressNote = (e) => {
    if (e.key === "Enter") {
      //console.log("Enter tuşlandı");
    }
  };

  // Gönder butonuna basıldığında çalışacak fonksiyon
  const handleButton = () => {
    Promise.all([
      addedBalance !== ""
        ? addBalance(email, walletID, myEmail, addedBalance, jwtToken)
        : null,
    ]).then((values) => {
      getBalanceLogs(myEmail, email, jwtToken, setBalanceLogs);
      setAddedBalance("");
      console.log(typeof balance);
      console.log(typeof addedBalance);

      setBalance(balance + parseFloat(addedBalance));
      notifySuccess("Cevabınız eklendi.");
    });
  };

  // MODALIN YÜKSEKLİĞİ İÇİN BÖLÜM

  const [modalHeight, setModalHeight] = useState(0);
  useEffect(() => {
    const updateModalHeight = () => {
      setModalHeight(window.innerHeight + 200);
    };

    // Sayfa yüklendiğinde ve pencere boyutu değiştiğinde modal yüksekliğini güncelle
    window.addEventListener("resize", updateModalHeight);
    updateModalHeight(); // Sayfa yüklendiğinde modal yüksekliği için başlangıç değerini ayarla

    // Component unmount olduğunda event listener'ı temizle
    return () => {
      window.removeEventListener("resize", updateModalHeight);
    };
  }, []); // Boş dependency array, sadece bir kere çalışmasını sağlar

  // ESC ye basıldığında modali kapatan bölüm
  useEffect(() => {
    const handleKeyPress = (event) => {
      // keyCode 27 corresponds to the "esc" key
      if (event.keyCode === 27) {
        setShowUserModal(false);
      }
    };

    // Event listener eklemek
    window.addEventListener("keydown", handleKeyPress);

    // Component unmount olduğunda event listener'ı kaldırmak
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [setShowUserModal]);

  // Ekran boyutu ile mobil olup olmadığını anladığımız yer
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);
    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (screenSize <= 900) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [screenSize]);

  return (
    <div className="m-2 md:m-4 p-4 md:p-6  ">
      {/* Sayfa Başlığı */}
      <h3
        style={{
          fontFamily: "Poppins Regular",
          fontSize: 24,
          color: "#333333",
        }}
      >
        Tüm Kullanıcılar
      </h3>

      {/* Arama - Search Bar */}
      <div className="flex mt-5 items-center">
        <input
          className=" shadow-md w-[320px] h-[50px] p-2 pl-5"
          placeholder="Aramak istedğiniz terimi girin"
          style={{ borderRadius: 4, borderColor: "#707070", outline: "none" }}
          value={searchText} // Arama metnini bağlayın
          onChange={handleSearchTextChange} // Metin değiştiğinde işlevi çağırın
          onKeyPress={handleKeyPress}
        />
        <button
          className="w-[80px] h-[50px] bg-white shadow-md hover:shadow-xl"
          style={{
            borderLeftWidth: 1,
            borderColor: "#707070",
            borderTopRightRadius: 4,
            borderBottomRightRadius: 4,
            fontFamily: "Poppins Regular",
            fontSize: 20,
            color: "#333333",
          }}
          onClick={performSearch} // Arama düğmesine tıklandığında işlevi çağırın
        >
          Ara
        </button>
      </div>

      {/* TÜM DATA */}
      <div className=" mt-20">
        <div className="flex justify-between w-[96%] items-center mt-[-28px] ">
          {/* Başlıklar */}
          {!isMobile &&
            tableData.map((item) => (
              <button
                key={item.title}
                style={{
                  fontFamily: "Montserrat Regular",
                  fontSize: 18,
                  color: "#333333",
                  opacity: 0.6,
                  width: item.title === "ID" ? 50 : 200,
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  marginLeft: 10,
                }}
                onClick={() => {
                  onTitleClickHandler(item.title);
                }}
              >
                {item.title}
                {item.title === sortedName &&
                  (sorted ? (
                    <AiFillCaretDown
                      style={{ marginLeft: 5, color: "#333333" }}
                    />
                  ) : (
                    <AiFillCaretDown
                      style={{
                        marginLeft: 5,
                        color: "#333333",
                        transform: "rotate(180deg)",
                      }}
                    />
                  ))}
              </button>
            ))}
        </div>

        {/* Müşteriler */}
        {!isMobile &&
          (searched
            ? searchResult.map((item, index) => (
                <SingleUserCard
                  item={item}
                  key={index}
                  id={index}
                  pageName="Aranacak"
                  isSelectAll={selectAll}
                  onClick={() => {
                    setClickedTicketID(item.id);
                    setClickedTicketTitle(item.name);
                    setClickedUser(item);
                    setEmail(item.email);
                    setWalletID(item.walledID);
                    setBalance(item.balance);
                    setStartDate(new Date(item.createdAt).getTime()); // burada kaldık, hata verdi tarih düzenlenecek.

                    setShowUserModal(true);
                  }}
                />
              ))
            : sorted
            ? sortedData.map((item, index) => (
                <SingleUserCard
                  item={item}
                  key={index}
                  id={index}
                  pageName="Aranacak"
                  isSelectAll={selectAll}
                  onClick={() => {
                    setClickedTicketID(item.id);
                    setClickedTicketTitle(item.name);
                    setClickedUser(item);
                    setEmail(item.email);
                    setWalletID(item.walledID);
                    setBalance(item.balance);
                    setStartDate(new Date(item.createdAt).getTime()); // burada kaldık, hata verdi tarih düzenlenecek.

                    setShowUserModal(true);
                  }}
                />
              ))
            : users.map((item, index) => (
                <SingleUserCard
                  item={item}
                  key={index}
                  id={index}
                  pageName="Aranacak"
                  isSelectAll={selectAll}
                  onClick={() => {
                    setClickedTicketID(item.id);
                    setClickedTicketTitle(item.name);
                    setClickedUser(item);
                    setEmail(item.email);
                    setWalletID(item.walledID);
                    setBalance(item.balance);
                    setStartDate(new Date(item.createdAt).getTime()); // burada kaldık, hata verdi tarih düzenlenecek.

                    setShowUserModal(true);
                  }}
                />
              )))}

        {/* MOBILE DEVICE TICKET CARD */}
        {isMobile && searched
          ? searchResult.map((item, index) => (
              <button
                key={index}
                className="w-full bg-white h-[48px] rounded-md shadow-md flex items-center justify-between px-2 mt-4"
                onClick={() => {
                  setClickedTicketID(item.id);
                  setClickedTicketTitle(item.name);
                  setClickedUser(item);
                  setEmail(item.email);
                  setWalletID(item.walledID);
                  setBalance(item.balance);
                  setStartDate(new Date(item.createdAt).getTime()); // burada kaldık, hata verdi tarih düzenlenecek.

                  setShowUserModal(true);
                }}
              >
                {/* Ticket Başlığı */}
                <div className="">
                  <p style={{ fontFamily: "Poppins Regular" }}>
                    {item.name.length > 25
                      ? `"${item.name.slice(0, 25)}..."`
                      : `"${item.name}"`}
                  </p>
                </div>

                {/* Açık - Kapalı Kutu */}
                <div
                  className={`w-[80px] h-[70%] ${
                    item.isOpen ? "bg-green-600" : " bg-gray-600"
                  } items-center flex justify-center rounded-md`}
                >
                  <p style={{ fontFamily: "Poppins Regular", color: "white" }}>
                    {item.isOpen ? "Açık" : "Kapalı"}
                  </p>
                </div>
              </button>
            ))
          : isMobile &&
            users.map((item, index) => (
              <button
                key={index}
                className="w-full bg-white h-[48px] rounded-md shadow-md flex items-center justify-between px-2 mt-4"
                onClick={() => {
                  setClickedTicketID(item.id);
                  setClickedTicketTitle(item.name);
                  setClickedUser(item);
                  setEmail(item.email);
                  setWalletID(item.walledID);
                  setBalance(item.balance);
                  setStartDate(new Date(item.createdAt).getTime()); // burada kaldık, hata verdi tarih düzenlenecek.

                  setShowUserModal(true);
                }}
              >
                {/* Ticket Başlığı */}
                <div className="">
                  <p style={{ fontFamily: "Poppins Regular" }}>
                    {item.name.length > 25
                      ? `"${item.name.slice(0, 25)}..."`
                      : `"${item.name}"`}
                  </p>
                </div>

                {/* Açık - Kapalı Kutu */}
                <div
                  className={`w-[80px] h-[70%] ${
                    item.isOpen ? "bg-green-600" : " bg-gray-600"
                  } items-center flex justify-center rounded-md`}
                >
                  <p style={{ fontFamily: "Poppins Regular", color: "white" }}>
                    {item.isOpen ? "Açık" : "Kapalı"}
                  </p>
                </div>
              </button>
            ))}

        {/* KULLANICI DETAYLARI MODAL */}
        {showUserModal && (
          <div
            className="fixed inset-0 bg-[rgba(0,0,0,0.5)] flex justify-center items-center z-20"
            onClick={() => {
              setShowUserModal(false);
            }}
          >
            {/* Modal İçi Container */}
            <div
              className={`w-[96%]  md:w-[600px]  bg-[#F2F2F2] rounded-sm md:px-10 md:py-8 z-10`}
              onClick={(e) => e.stopPropagation()}
            >
              <div>
                <div className=" m-2 md:m-4 p-4 md:p-6 flex justify-between flex-wrap relative ">
                  {/* SOL TARAF - YENI KAYIT EKLE BOLUMU */}
                  <div className="w-full md:w-auto">
                    {/* User Name */}
                    <div className="mt-5 w-full md:w-[300px]">
                      <div
                        style={{
                          fontFamily: "Montserrat Regular",
                          fontSize: 18,
                          color: "#333333",
                          opacity: 0.6,
                        }}
                      >
                        İsim
                      </div>
                      <input
                        className=" shadow-md w-[100%] md:w-[400px] h-[50px] p-2 pl-5"
                        placeholder="İsim Soyisim"
                        readOnly={true}
                        style={{
                          borderRadius: 4,
                          borderColor: "#707070",
                          outline: "none",
                          marginTop: 5,
                        }}
                        value={clickedTicketTitle} // Arama metnini bağlayın
                      />
                    </div>

                    {!isMobile && (
                      <>
                        {/* Email Input */}
                        <div className="mt-5">
                          <div
                            style={{
                              fontFamily: "Montserrat Regular",
                              fontSize: 18,
                              color: "#333333",
                              opacity: 0.6,
                              width: 300,
                            }}
                          >
                            E-mail
                          </div>
                          <input
                            className=" shadow-md w-[96%] md:w-[400px] h-[50px] p-2 pl-5"
                            placeholder="E-mail"
                            readOnly={true}
                            style={{
                              borderRadius: 4,
                              borderColor: "#707070",
                              outline: "none",
                              marginTop: 5,
                            }}
                            value={email} // Arama metnini bağlayın
                            onChange={(e) => {
                              setEmail(e.target.value);
                            }} // Metin değiştiğinde işlevi çağırın
                          />
                        </div>

                        {/* IP Input */}
                        <div className="mt-5">
                          <div
                            style={{
                              fontFamily: "Montserrat Regular",
                              fontSize: 18,
                              color: "#333333",
                              opacity: 0.6,
                              width: 300,
                            }}
                          >
                            IP Adresi
                          </div>
                          <input
                            className=" shadow-md w-[400px] h-[50px] p-2 pl-5"
                            placeholder="Cüzdan"
                            readOnly={true}
                            style={{
                              borderRadius: 4,
                              borderColor: "#707070",
                              outline: "none",
                              marginTop: 5,
                            }}
                            value={clickedUser.ip} // Arama metnini bağlayın
                          />
                        </div>

                        {/* Tarih Input */}
                        <div className="flex w-[400px]  justify-between">
                          {/* Tarih Input */}
                          <div className="mt-5 ">
                            <div
                              style={{
                                fontFamily: "Montserrat Regular",
                                fontSize: 18,
                                color: "#333333",
                                opacity: 0.6,
                                width: 180,
                              }}
                            >
                              Üyelik Tarihi
                            </div>

                            <div className=" pl-5 bg-white mt-[5px] ">
                              <DatePicker
                                className="h-[50px] w-[150px] "
                                readOnly={true}
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                timeInputLabel="Saat:"
                                dateFormat="dd/MM/yyyy HH:mm"
                                showTimeInput
                                locale="tr"
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    <button
                      className={`p-4 px-10 mt-10 ${
                        clickedUser.deleted ? "bg-gray-600" : "bg-red-500"
                      }  shadow-md hover:shadow-xl`}
                      style={{
                        borderWidth: 1,
                        borderColor: "#707070",
                        borderTopRightRadius: 4,
                        borderBottomRightRadius: 4,
                        fontFamily: "Poppins Medium",
                        fontSize: 20,
                        color: "white",
                      }}
                      disabled={clickedUser.deleted}
                      onClick={() => {
                        Swal.fire({
                          title: "Uyarı",
                          text: "Kullanıcıyı silmek istediğinize emin misiniz?",
                          icon: "warning",
                          showCancelButton: true,
                          confirmButtonColor: "red",
                          cancelButtonColor: "#3085d6",
                          cancelButtonText: "İptal",
                          confirmButtonText: "Sil",
                        }).then((result) => {
                          if (result.isConfirmed) {
                            deleteUser(myEmail, jwtToken, clickedUser.email);
                          }
                        });
                      }}
                    >
                      {clickedUser.deleted
                        ? "Kullanıcı Silinmiş"
                        : "Kullanıcıyı Sil"}
                    </button>
                  </div>

                  <button
                    className="absolute top-2 right-2"
                    onClick={() => {
                      setShowUserModal(false);
                    }}
                  >
                    <MdOutlineCancel size={36} color="red" />
                  </button>
                </div>
                <ToastContainer />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomerTicketsPage;
